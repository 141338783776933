<template>
  <v-dialog v-model='previewDialog.show' width='50%'>
    <v-card v-if='previewDialog.show' style='background-color: white;'>
      <v-container fluid class='pa-0'>
        <v-app-bar dark color='primary'>
          <v-tooltip bottom>
            <template #activator='{ on }'>
              <v-btn icon v-on='on' @click.native='previewDialog.show = false'>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('CLOSE')}}</span>
          </v-tooltip>

          <div class='ml-2 d-flex flex-column'>
            <div>
              <span class='text-h5'> Pré-visualização </span>
              <span style='font-size: 0.8em'> ({{ previewDialog.template.name }}) </span>
            </div>
            <span> {{ previewDialog.id }} </span>
          </div>
        </v-app-bar>
        <div class='d-flex justify-center pa-5'>
          <Canvas :id='previewDialog.id' :values='previewDialog.values' :template='previewDialog.template' />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: 'PreviewDialog',
    props: {
      previewInfo: {
        type: Object,
        default: () => {},
      },
    },
    components: {
      Canvas: () => import('../Canvas/CNH/Canvas'),
    },
    data: function () {
      return {
        previewDialog: {},
      };
    },
    mounted: function () {
      this.previewDialog = this.previewInfo;
    },
  };
</script>
